<template>
    <div>
        <Header headerType="advance_result"/>  


        <div :class="'container-fluid' + ($route.query.zoom ? ' fullscreen' : '') + (isUserFrench ? ' page-french' : '')">
            <div class="row">
                <div class="main-box-fixed">                        
                    <div class="box-fixed" >
                        <div class="title">
                            <h1>{{ count }}</h1>
                            <h2>{{$t('Result')}}</h2>
                        </div>
                    </div>
                    <div class="advance box-fix-left" ref="menuFlag" style="top:0px;">
                        <ul>
                            <label class="flag-active">  
                                                             
                                <li class="active pointerNone" >
                                    <img :src="require('@/assets/images/flag/new/fr.svg')" width="35" height="35" style="margin-bottom: 5px;" />
                                    <p> 
                                        <span style="margin-right: 0;"></span>
                                        RTU
                                    </p>
                                    <p class="short_name">(FRA)</p> 
                                </li>                                
                            </label>

                             

                        </ul>
                    </div>
                </div>

                <div class="main-body">
                    <div class="body-fix">
                        <div class="body-custom">
                            <div class="box-btn-menu">  
                                <a href="javascript:void(0)" class="btn btn-border-orange btn-muted pointerNone">{{$t('Overview')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('expert')" :class="'btn ' + (!$route.query.tab || $route.query.tab == 'expert' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Expert')}}</a>
                                <a v-if="user && user.access_clinical" href="javascript:void(0)" @click="selectTab('essais_clinique')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'essais_clinique' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Clinical trials')}}</a>
                                <a href="javascript:void(0)" @click="selectTab('prix')" :class="'btn ' + ($route.query.tab && $route.query.tab == 'prix' ? ' btn-orange' : 'btn-border-orange')" >{{$t('Prix')}}</a>
                            </div>
                        </div>
                    </div>
                        
                    <div v-if="(!$route.query.tab || $route.query.tab == 'expert')">   
                    <div class="body-w100" v-if="data.data && data.data.length > 0">
                        <div class="box-detail">                           
                            <ModalConfigColumn :page="page" :type="type" :column="orderColumn" :reset_column="reset_column" /> 
                            <ModalSaveKeyword :type="type" />
                            <IconTopTable @onRequest="request_data" :page="page" @onSelectExport="selectExport" @onClearExport="clearExport"/>
                            <div class="content-table-results mt-2">
                                <table class="table table-fixed table-stripes">
                                    <ColumnColGroup :page="page" :column="orderColumn" :default_column="default_column" />
                                    <ColumnThead :page="page" :column="orderColumn" :default_column="default_column"  /> 
                                    <tbody> 
                                        <tr v-for="(item, key) in data.data" :key="key" >                
                                            
                                            <td>
                                                <label class="table-check">
                                                    <input type="checkbox"  v-model="select_export" :value="item.drug_id">
                                                    <span class="checkbox-table"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <span v-html="icon_link(item.drug_id,'rtu')"></span>
                                                <div v-if="isAdmin">
                                                    <a :href="modifyPage('rtu', item.drug_id)" target="_blank" rel="noopener"> 
                                                        <em class="icon-edit"></em>
                                                    </a>
                                                </div>
                                            </td>
                                            <template v-for="(column) in orderColumn" :key="column.code" >

                                                <td v-if="column.Status && column.code == 'rtu_001'" :code="column.code">
                                                    {{item.drug_id}}
                                                </td>
                                                <!-- <td v-else-if="column.Status && column.code == 'rtu_002'" :code="column.code">                                                           
                                                    <span v-html="icon_link(item.drug_id,'rtu')"></span>
                                                </td> -->
                                                <td v-else-if="column.Status && column.code == 'rtu_003'" :code="column.code">
                                                    {{check_empty(item.category)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_004'" :code="column.code">
                                                    {{check_empty(item.dci_new)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_005'" :code="column.code">
                                                    {{check_empty(DDMMMYY(item.rtu.date))}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_006'" :code="column.code">
                                                    {{check_empty(item.dci)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_007'" :code="column.code">
                                                    {{check_empty(item.name)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_008'" :code="column.code">
                                                    {{check_empty(item.firm)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_009'" :code="column.code">
                                                    <span v-html="check_empty(array(item.atc))"></span>
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_010'" :code="column.code">
                                                    <Readmore :longText="item.rtu.indication" />
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_011'" :code="column.code">
                                                    {{check_empty(item.rtu.avis_pour_prise)}}
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_012'" :code="column.code">
                                                    <Readmore :longText="Critere_prise_en_charge(item.rtu)" />
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_013'" :code="column.code">
                                                    <Readmore :longText="item.rtu.nombre_de_patients_concernes" />
                                                </td>
                                                <td v-else-if="column.Status && column.code == 'rtu_014'" :code="column.code">
                                                    {{check_empty(item.mk_orphelin)}}
                                                </td>

                                                

                                            </template>

                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <!---- Area Table Result End----->
                             
                            
                        </div>
                    </div> 

                    <div v-else class="body-w100" >
                        <div class="box-detail">
                            <div class="wrapper-box-hta">
                                <h1 class="fa-pull-left mr-4 mt-1">{{$t('No Data')}}</h1>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>   
                    </div>

                    <TablePrix v-if="$route.query.tab == 'prix'" main_page="Rtu" />

                    <TableEssaisClinique v-if="$route.query.tab == 'essais_clinique'" main_page="Rtu" />
 
                </div>
            </div>
        </div>


        <Loader :isLoad="isLoad" />
        <Footer />
    </div>
    
</template>

<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import IconTopTable from '@/components/elements/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue'; 
import ModalConfigColumn from '@/components/elements/modal_config_column.vue'
import ModalSaveKeyword from '@/components/elements/modal_save_keyword.vue'
import TablePrix from '@/components/advanced_result/table_prix.vue';
import TableEssaisClinique from '@/components/advanced_result/table_essais_clinique.vue';
import { currentDate, isAdmin, isUserFrench, eventHandlerResize, array, DDMMMYY, icon_link, modifyPage, check_empty } from '@/utils';
import Loader from "@/components/Layout/Loader";

export default {
    name: 'advanced_result_rtu',
    components: {        
        Header,
        Footer,   
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        ModalConfigColumn,
        ModalSaveKeyword,
        TablePrix,
        TableEssaisClinique,
        Readmore,
        Loader
    },
    data() {
        return {
            page : "rtu",
        }
    },
    mounted() {
        //search
        this.request_data({ mounted : true, order_by : 'decision_date_num', sort : 'desc' }) 
        document.body.classList.add('body-bg-gray')   
        if(this.$route.query.zoom){
            this.eventHandlerResize()
        }
    },
    methods: {
        request_data(param = {}){
            let paramObj = {...this.$route.params}
            paramObj['base64Search'] = paramObj['base64Search'].replace('/\-/g', '+').replace('/_/g', '/') // eslint-disable-line
            if(!param['mount'] && this.sort_column && Object.keys(this.data).includes(this.sort_column['order_by'])){
                paramObj['order_by'] = this.sort_column['order_by']
            }
            else if(param['order_by']){
                paramObj['order_by'] = param['order_by']
            }
            else{
                paramObj['order_by'] = 'decision_date_num'
            }
            paramObj['sort'] = !param['mounted'] && this.sort_column && this.sort_column['sort'] ? this.sort_column['sort'] : param['sort']
            
            if(param.page){
                paramObj['page'] = param.page
            }
            if(this.select_export_obj.isSelect){
                paramObj['select_export'] = this.select_export_obj.listID
            }
            if(this.data_filter !== ""){
                paramObj['filter'] = this.data_filter
            }
            if(this.$route.query.keyword_id){
                paramObj['keyword_id'] = this.$route.query.keyword_id
            }
            if(param.export){
                delete param.export;
                this.$store.dispatch(`advanced_result/result_rtu_excel`, [paramObj, currentDate()+"_advanced_rtu" ] ) 
            }else{
                this.$store.dispatch(`advanced_result/result_rtu`, paramObj ) 
            }
            
            if(!param.mounted){
                this.selectTab('expert')
            }
        },  
        selectTab(tab){
            let query = Object.assign({}, this.$route.query);
            if(query.tab !== tab){
                query.tab = tab            
                console.log(this.$route.query)
                this.$router.push({ query });  
            }                       
        },
        selectExport(){
            this.$store.dispatch("advanced_result/select_export", { key : "isSelect" , value : true})                 
            this.request_data() 
        },
        clearExport(){
            this.$store.dispatch("advanced_result/select_export", "clear")                      
            this.request_data()
        },
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
        Critere_prise_en_charge(param){            
            let txt = "";
            if(param.absence_dalternative){
                txt += `<div>${this.$t('rtu.absence_dalternative')}</div>`
            }
            if(param.utilisation){
                txt += `<div>${this.$t('rtu.utilisation')}</div>`
            }
            if(param.autre){
                txt += `<div>${param.autre_text}</div>`
            }
            return txt
        },
        eventHandlerResize,
        modifyPage,
        array,
        DDMMMYY,
        icon_link,
        check_empty
    },
    computed: {
        isAdmin,
        isUserFrench,
        user(){
            return this.$store.getters['auth/user']
        },
        data(){
            return this.$store.getters['advanced_result/data'];
        },
        orderColumn(){
            return this.$store.getters['advanced_result/column'];
        },
        default_column(){
            return this.$store.getters['advanced_result/default_column'];
        },
        type(){
            return this.$store.getters['advanced_result/type'] 
        },
        reset_column(){
            return this.$store.getters['advanced_result/reset_column']                       
        }, 
        openBoxSearch () {  
            return this.$store.getters['header/openBoxSearch']
        },                 
        count() {
            return this.$store.getters['advanced_result/count']
        },
        isLoad() {  
            if(this.$store.getters['advanced_result/load'] || this.$store.getters['advanced_result/prix_load'] || this.$store.getters['essais_clinique/load']){
                return true
            }
            return false
        },
        select_export: {
            set(val){ this.$store.dispatch("advanced_result/select_export", {key: 'listID', value: val}) },
            get(){ 
                if(this.$store.getters['advanced_result/select_export'].checkAll){
                    return true
                }else{
                    return this.$store.getters['advanced_result/select_export'] ? this.$store.getters['advanced_result/select_export'].listID : null
                }
            }
        },
        select_export_obj(){
            return this.$store.getters['advanced_result/select_export']
        },
        data_filter(){
            return this.$store.getters['advanced_result/data_filter']
        },
        sort_column(){
            return this.$store.getters['advanced_result/sort_column']
        }
    },
    created() {
        if(this.$route.query.zoom){
            window.addEventListener("resize", this.eventHandlerResize);
        }
    },
    unmounted () {
        document.body.classList.remove('body-bg-gray'); 
        this.$store.dispatch("advanced_result/clearState") 
        this.$store.dispatch("essais_clinique/clearState")
        if(this.$route.query.zoom){
            window.removeEventListener("resize", this.eventHandlerResize);
        }
    }
}
</script>

<style scoped>
.main-body{
    min-height: 654px;
}
.box-fix-left{ padding-top:133px}
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 60vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>